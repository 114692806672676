.landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 70px 100px;
  flex-direction: column;

  /* background-attachment: initial; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.landing h1 {
  font-family: lulo-clean-w01-one-bold, sans-serif;
  color: white;
  font-weight: bolder;
  font-size: 60px;
  letter-spacing: 1px;
  line-height: 1;
}
.landing h3 {
  font-family: lulo-clean-w01-one-bold, sans-serif;
  margin-top: 20px;
}



.home .section-2 {
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home .textWrpr {
  height: 30%;
  width: 70%;
  display: flex;
  flex-direction: column;
}
.home .qouteWrpr {
  display: flex;
  padding: 0px 30px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(51, 50, 50, 0.6);
}

.home .qouteWrpr h3 {
  color: #e2bc00;
  font-size: 50px;
  margin-right: 25px;
}
.home .qouteWrpr p {
  width: 90%;
}
.home .nameWrpr {
  margin-top: 20px;
  padding: 4px 50px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(51, 50, 50, 0.6);
  align-self: flex-end;
}
.home .nameWrpr h3 {
  font-size: 18px;
}
.home .about {
  display: flex;
  justify-content: flex-end;
  padding: 30px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.testimonials .sponsor {

  justify-content: center;
}

.testimonials .sponsor h1 {
  font-size: 25px;
  font-weight: normal;
  text-align: center;
}

.testimonials .sponsor .line-sponsor {
  height: 10px;
  width: 100%;
  background-color: #e2bc00;
  margin-bottom: 30px
}

.testimonials .sponsor .sponsor1 {
 margin-right: 40px
}


.home .about2 {
  display: flex;
  justify-content: flex-start;
  padding: 30px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home .about .content {
  padding-top: 50px;
  width: 50%;
  color: white;
}

.home .about .content2 {
  padding-top: 50px;
  width: 50%;
  color: white;
}

.home .about2 .content {
  padding-top: 50px;
  width: 50%;
  color: white;
}

.home .about2 .content2 {
  padding-top: 50px;
  width: 50%;
  color: white;
}
.home .about .sub {
  margin-bottom: 20px;
}

.home .about2 .sub {
  margin-bottom: 20px;
}
.home .about .sub .subwrpr p {
  font-size: 25px;
  width: 55%;
  font-weight: 700;
}

.home .about2 .sub .subwrpr p {
  font-size: 25px;
  width: 55%;
  font-weight: 700;
}
.home .about .name {
  font-size: 15px;
}

.home .about2 .name {
  font-size: 15px;
}
.home .about .name span {
  color: #e2bc00;
  font-size: 25px;
  font-weight: 400;
}

.home .about2 .name span {
  color: #e2bc00;
  font-size: 25px;
  font-weight: 400;
}
.home .about_2 {
  padding: 30px 40px;
  background-color: rgb(51, 51, 51);
  width: 80%;
}

.home .about_2_2 {
  padding: 30px 40px;
  background-color: rgb(51, 51, 51);
  width: 80%;
}
.home .about_2 h1 {
  font-size: 55px;
  font-weight: 700;
}

.home .about_2_2 h1 {
  font-size: 55px;
  font-weight: 700;
}
.home .about_2 p {
  text-align: justify;
}

.home .about_2_2 p {
  text-align: justify;
}
.line {
  height: 10px;
  width: 60px;
  background-color: #e2bc00;
  margin-bottom: 30px;
}
.home .lm_btn {
  margin: auto;
  margin-top: 40px;
  padding: 5px 5px;
  width: 25%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.home .aim_sec {
  display: flex;
  height: 100vh;
}
.home .aim_sec > div {
  width: 50%;
  background-color: rgb(51, 51, 51);
  color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.home .aim_text_wrpr {
  padding: 30px 70px;
}

.aim_text_wrpr h2 {
  font-size: 55px;
}
.aim_text_wrpr p {
  text-align: justify;
}
.services_1 {
  display: flex;
  background-color: rgb(70, 67, 67);
}
.services_1 > div {
  width: 50%;
}
.services_1 .text_wrpr {
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: white;
  text-align: justify;
}
.services_1 .text_wrpr h2 {
  font-size: 40px;
}
.services_1 .text_wrpr h3 {
  font-size: 20px;
}
.services_1 .img_wrpr {
  display: flex;
  align-items: center;
  justify-content: center;
}
.services_1 .img_wrpr img {
  object-fit: cover;
  /* width: 100%; */
  height: 90vh;
}
.services {
}
.services h1 {
  margin: 10px 100px;
  font-weight: bold;
  font-size: 65px;
}
.services .line {
  margin: 10px 100px;
  height: 10px;
  background-color: #e2bc00;
  width: 150px;
}
.services_img_wrpr {
  display: flex;
}
.service_item {
  position: relative;
  width: 25%;
  background-size: cover;
  height: 90vh;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.5s;
}
.service_btn {
  margin: 25% 50px;
  padding: 4px 40px;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.service_btn:hover {
  color: black;
  background-color: white;
}
.service_info {
  position: absolute;
  height: 90vh;
  width: 100%;
  background-color: rgba(99, 95, 95, 0.884);
  bottom: -90vh;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.service_info h3 {
  margin: 200px 40px 0 40px;
}
.service_info p {
  margin: 0px 40px 0 40px;
}
.service_info ul {
  margin-top: 20px;
}
.accelerate_sec {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(46, 44, 44);
  color: white;
}
.accelerate_sec h1,
.testimonials h1 {
  font-size: 50px;
  font-weight: bold;
}
.accelerate_sec > p,
.testimonials > p {
  font-size: 50px;
  font-weight: lighter;
}
.accelerate_sec .line,
.testimonials .line {
  height: 5px;
  background-color: #e2bc00;
  width: 250px;
}
.accelerate_sec .items_wrpr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 100px;
  flex-wrap: wrap;
}
.acc_item {
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.acc_item .no {
  font-size: 70px;
}
.acc_item .title {
  font-size: 20px;
}
.testimonials {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonials .item_wrpr {
  width: 70%;
  margin: 20px;
}
.testimonials .item {
  display: flex;
  align-items: center;
}
.testimonials .item img {
  margin: 20px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
}
.testimonials .item div {
  width: 80%;
  margin-left: 50px;
}
.testimonials .quote {
  font-size: 15px;
}
.testimonials .name {
  font-size: 13px;
}

@media (min-width: 320px) and (max-width: 480px) {

  .testimonials .sponsor .line-sponsor {
    margin-bottom: 0px
  }

  .testimonials .sponsor .sponsor1 {
    margin-right: 0
  }

  .testimonials .sponsor .sponsorImages{
    text-align: center;
   
  }
  .testimonials .item {
    display: flex;
    flex-direction: column;
  }
  .testimonials .quote {
    font-size: 15px;
    margin-left: -50px;
  }

  .testimonials .name {
    font-size: 13px;
    margin-left: -50px;
  }
  .landing {
    height: 85vh;
    padding: 70px 20px;

    background-size: cover;
    background-position: center;
    /* background-position: center top; */
    /* background-attachment: fixed; */
  }
  .service_btn1 {
    border: 2px solid white;
    color: white;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
  }

  .landing h1 {
    color: white;
    font-size: 28px;
    margin-bottom: 20px;
  }
  .landing h3 {
    font-size: 14px;
    margin-top: 10px;
  }
  .home .section-2 {
    justify-content: flex-end;
  }
  .home .textWrpr {
    margin-top: 100px;
    width: 100%;
  }

  .home .qouteWrpr {
    padding: 10px;
  }
  .home .qouteWrpr p {
    font-size: 12px;
  }
  .home .about {
    height: 100%;
    justify-content: center;
    padding: 30px 0;
    margin-top: -300px;
    background-position: 14%;
    /* background-attachment: scroll; */
  }

  .home .about2 {
    height: 100%;
    justify-content: center;
    padding: 30px 0;
    /* background-attachment: scroll; */
  }
  .home .about .content {
    margin-top: 30vh;
    width: 100%;
    /* background: #e2bc00; */
  }

  .home .about2 .content2 {
    margin-top: 30vh;
    width: 100%;
    /* background: #e2bc00; */
  }
  .home .about .sub {
    padding: 0 0 0 30vw;
  }
  .home .about2 .sub {
    padding: 0 0 0 30vw;
  }
  .home .about .sub .subwrpr p {
    font-size: 18px;
    width: 90%;
    font-weight: 700;
  }

  .home .about2 .sub .subwrpr p {
    font-size: 18px;
    width: 90%;
    font-weight: 700;
  }
  .home .about .name {
    font-size: 12px;
  }

  .home .about2 .name {
    font-size: 12px;
  }
  .home .about .name span {
    font-size: 18px;
    font-weight: 200;
  }

  .home .about2 .name span {
    font-size: 18px;
    font-weight: 200;
  }
  .home .lm_btn {
    width: 50%;
  }
  .home .about_2 {
    padding: 30px 20px;
    width: 100%;
    height: 100vh;
  }

  .home .about_2_2 {
    padding: 30px 20px;
    width: 100%;
  }
  .home .about_2 h1 {
    font-size: 35px;
  }
  .home .about_2_2 h1 {
    font-size: 35px;
  }
  .home .about_2 p {
    font-size: 12px;
  }

  .home .about_2_2 p {
    font-size: 12px;
  }
  .aim_sec {
    height: 60vh;
    flex-wrap: wrap;
    /* flex-wrap: wrap-reverse; */
  }
  .home .aim_sec > div {
    width: 100%;
    height: 65vh;

    justify-content: flex-start;
  }
  .home .aim_text_wrpr {
    padding: 30px 20px;
  }
  .home .aim_text_wrpr h2 {
    font-size: 35px;
  }
  .home .aim_text_wrpr p {
    font-size: 12px;
  }
  .corousel_wrpr {
    display: none;
  }
  .services_1 {
    flex-wrap: wrap;
    margin-top: -300px;
  }
  .services_1 > div {
    width: 100%;
  }
  .services h1 {
    margin: 80px 30px 10px 30px;
    font-weight: bold;
    font-size: 40px;
  }
  .services_1 {
  }
  .services_1 h3 {
    font-size: 13px;
  }
  .services_1 p {
    font-size: 13px;
  }
  .services_1 ul {
    font-size: 13px;
  }
  .services .line {
    margin: 10px 30px;
    width: 70px;
  }

  .services_img_wrpr {
    flex-wrap: wrap;
  }
  .services_1 .img_wrpr img {
    object-fit: cover;
    /* height: 40vh; */
    height: auto;
    width: 100vw;
  }

  .service_item {
    position: static;
    margin: 20px 0;
    height: 50vh;
    width: 80%;
  }
  .service_info {
    height: 50vh;

    overflow-y: scroll;
  }
  .service_info h3 {
    margin: 750px 30px 0 30px;
  }
  .accelerate_sec h1,
  .testimonials h1 {
    font-size: 25px;
  }
  .accelerate_sec > p,
  .testimonials > p {
    font-size: 30px;
    font-weight: lighter;
  }
  .accelerate_sec .items_wrpr {
    margin: 10px 10px;
  }
  .acc_item {
    margin: 20px 10px;
  }
  .acc_item .no {
    font-size: 30px;
  }
  .acc_item .title {
    font-size: 18px;
    text-align: center;
  }
  .testimonials {
    flex-wrap: wrap;
  }
}

.header {
  width: 100vw;
  background-color: white;
  height: 60px;
  color: black;
  z-index: 100;
  position: fixed;
  align-items: center;
}
.info {
  padding-top: 200px;
  height: 70vh;
  background-color: #d0d0d0;
}

.gallery {
  font-size: 40px;
  padding-top: 40px;
  padding-left: 280px;
}
.header_content {
  padding-top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header_logo {
  margin-left: 50px;
}
.header_logo img{
  width: auto;
  height: 50px;
}
.blue {
  width: 120px;
  height: 10px;

  margin-top: 20px;
  background-color: #fefefe;
}
.header_items{
  padding: 20px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .header {
    height: 50px;
    padding: 10px;
  }

  .header_content {
    /* padding-top: 40px; */
    justify-content: space-between;
  }
  .header_logo {
    margin-left: 30px;
  }
  .header_logo img{
    width: 40px;
    height:40px;
  }
  .header_items {
    width: 200px;
    padding-left: 40px;
  }
  .info {
    height: 80vh;
    text-align: center;
  }
  .gallery {
    padding-left: 20px;
  }
  .blue {
    margin-left: 120px;
  }
}